import React, { useState } from 'react';
import { graphql, StaticQuery } from 'gatsby';
import styled from 'styled-components';
import flatten from 'lodash.flatten';
import Layout from 'components/layout';
import SEO from 'components/seo';
import BreadCrumb from 'components/breadCrumb';
import HeaderIntern from 'components/contactUs/headerIntern';
// import { apiLeadOuvidoria } from 'components/services/salesForceAPI';
import { device } from 'components/device';
import SuccessScreen from './successScreen';
import {
  TmpInput,
  TmpButton,
  TmpTextarea,
  TmpCheckbox,
  TmpLink,
  TmpIcon,
} from '@tempo/tempo-design-system-core/dist';
import {  info as Info } from '@tempo/tempo-assets/dist';
import axios from 'axios';

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: auto repeat(12, 70px) auto;
  column-gap: 30px;

  a,
  em {
    color: #45a7df;
  }

  em {
    font-style: normal;
    color: #8f9194;
  }

  code {
    margin-top: 1.375rem;
    margin-left: 2.8125rem;
    color: #8f9194;
    font-size: unset;
    font-family: unset;
    background: unset;
  }

  @media ${device.laptop} {
    display: block;
  }
`;

const Wrapper = styled.div`
  grid-column: 2 / -2;
`;

const ContainerContent = styled.div`
  display: inline-grid;
  grid-column: 5 / 11;
  flex-direction: column;
  justify-content: center;

  p {
    em {
      margin-top: 15px;
      top: 0;
      display: inline-block;
      @media ${device.mobile} {
        margin-left: 44.8px;
        margin-top: -5px;
      }
    }
    img {
      :nth-child(1) {
        top: 1.1875rem;
        @media ${device.laptop} {
          top: 1.1875rem;
        }
      }

      :nth-child(2n) {
        position: relative;
        top: 6px;
        @media ${device.mobile} {
          top: 30px;
        }
      }
    }
  }

  section {
    p {
      :nth-child(3n) {
        img {
          position: relative;
          top: 3.4rem;
        }
      }
    }
  }

  @media ${device.mobile} {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  @media ${device.laptop} {
    padding: 0 1.25rem;
    display: flex;

    p {
      width: 100%;
      :first-child {
        padding-bottom: 28px;
        @media ${device.mobile} {
          padding-bottom: 20px;
        }
      }
    }
  }
`;

const Container = styled.div`
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  background-color: #c5d7fb;
  @media (max-width: 648px) {
    background-color: #ffff;
  }
`;

const Box = styled.div`
  flex-direction: column;
  background-color: #ffffff;
  width: 70%;
  align-items: center;
  border-radius: 10px;
  margin: 80px 300px;
  padding: 80px;

  .switch {
    position: relative;
    display: inline-block;
    width: 50px;
    height: 24px;
  }

  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  .slider:before {
    position: absolute;
    content: '';
    height: 16px;
    width: 16px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  input:checked + .slider {
    background-color: #2c6ef2;
  }

  input:focus + .slider {
    box-shadow: 0 0 1px #2196f3;
  }

  input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }

  .slider.round {
    border-radius: 34px;
  }

  .slider.round:before {
    border-radius: 50%;
  }

  @media ${device.mobile} {
    // width: 100%;
    // flex-direction: column;
    // padding: 10px;
    // margin: 20px;

    width: 90%; /* Reduz a largura do box */
    margin: 20px; /* Reduz as margens */
    padding: 20px; /* Reduz o preenchimento */
  }
`;

const Title = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 30px;
  font-weight: 700;
  line-height: 26.4px;
  text-align: center;

  @media ${device.mobile} {
    font-size: 24px;
  }
`;

const SubTitle = styled.div`
  width: 100%;
  display: flex;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 20px;
  font-weight: 700;
  line-height: 21.28px;
  letter-spacing: -0.01em;
  text-align: left;
  margin: 30px 0;

  @media ${device.mobile} {
    font-size: 18px;
  }
`;

const NameContainer = styled.div`
  display: flex;
  gap: 20px;
  @media ${device.mobile} {
    flex-direction: column;
    width: 100%;
    gap: 0;
  }
`;

const NameContent = styled.div`
  width: 100%;
`;

const AnonimousDiv = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start !important;
  align-items: flex-start;
  gap: 10px;
`;

const AnonimousTitle = styled.div`
  font-family: Arial, Helvetica, sans-serif;
  font-size: 18px;
  font-weight: 500;
  line-height: 16px;
  text-align: left;
`;

const DivInput = styled.div`
  width: 100%;
  margin: 10px 0px 50px;
  #message:hover {
    background-color: rgba(197, 215, 251, 0.32);
    font-family: 'Inter', sans-serif;
  }
  #name,
  #lastName,
  #email,
  #subject,
  #description {
    font-family: 'Inter', sans-serif;
  }
  label {
    font-family: 'Inter', sans-serif;
  }
  span {
    font-family: 'Inter', sans-serif;
  }

  @media ${device.mobile} {
    width: 90%;
  }
`;

const InputDiv = styled.div`
  width: 100%;
  #message:hover {
    background-color: rgba(197, 215, 251, 0.32);
    font-family: 'Inter', sans-serif;
  }
  #subject,
  #description {
    font-family: 'Inter', sans-serif;
  }
  label {
    font-family: 'Inter', sans-serif;
  }
  span {
    font-family: 'Inter', sans-serif;
  }

  @media ${device.mobile} {
    width: 90%;
  }
`;

const Button = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  .tmp-button {
    width: 15%;
    font-family: 'Inter', sans-serif;
    @media (max-width: 648px) {
      width: 100%;
    }
  }
`;

const ErrorMessage = styled.div`
  color: red;

  @media ${device.mobile} {
    font-size: 14px;
  }
`;

const CheckBoxContainer = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 30px;

  //   @media ${device.mobile} {
  //     flex-direction: column;
  //   }
`;

const CheckBoxTtile = styled.div`
  width: 100%;
  font-family: 'Inter', sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 16px;
  text-align: left;

  .tmp-link {
    font-family: 'Inter', sans-serif;
  }

  @media ${device.mobile} {
    font-size: 14px;
  }
`;

const InformationContent = styled.div`
  display:flex;
  flex-direction: column;
  justify-content:center;
  padding:20px;
  align-items:center;
  width: 100%;

  background-color:#F5F5F5;

  border-radius: 4px;
  gap:10px;
`;

const InformationTitle = styled.div`
  display:flex;
  justify-content:center;
  text-align:left;
  align-items:center;
  width: 100%;
  font-family: 'Inter', sans-serif;
  font-size: 16px;
  font-weight: 700;
  border-radius: 4px;
  gap:10px;
`;

const TitleContent = styled.div`
width:100%;
 font-size: 14px;
  font-weight: 400;
  text-align:justify;
`
const getMarkdown = (page, fragmentId, isMarkdown = false) => {
  const { fragments } = page[0];
  const findFragment = fragments.find(item => item.id === fragmentId);

  if (isMarkdown) {
    const { markdown } = flatten(findFragment.localizations)[0];
    return markdown;
  } else {
    return flatten(findFragment.localizations);
  }
};

const FormOuvidoria = ({ page }) => {
  const isWindow = typeof window !== 'undefined';
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    subject: '',
    description: '',
    isAnonimous: false,
  });

  const [success, setSuccess] = useState(false);
  const [errors, setErrors] = useState({});
  const [isChecked, setIsChecked] = useState(false);
  const ref = React.useRef(null);

  const handleChange = e => {
    const { id, value } = e.target;
    if (id === 'name') {
      const [firstName, lastName] = value.split(' ');
      setFormData({ ...formData, firstName, lastName });
    } else if (id === 'email') {
      if (!formData.isAnonimous) {
        setFormData({ ...formData, email: value });
      }
    } else if (id === 'anonimous') {
      setFormData({ ...formData, isAnonimous: !formData.isAnonimous });
    } else {
      setFormData({ ...formData, [id]: value });
    }
  };

  const handleCheckboxChange = e => {
    setIsChecked(prevChecked => !prevChecked); // Inverte o valor de isChecked
  };

  const validateForm = () => {
    const errors = {};
    if (!isChecked) {
      errors.checkbox = 'Você precisa aceitar os termos e condições';
    }
    if (!isChecked || !formData.isAnonimous) {
      if (!formData.firstName) {
        errors.firstName = 'Nome é obrigatório';
      }
      if (!formData.lastName) {
        errors.lastName = 'Sobrenome é obrigatório';
      }
      if (!formData.email) {
        errors.email = 'E-mail é obrigatório';
      }
    }
    if (!formData.subject) {
      errors.subject = 'Assunto é obrigatório';
    }
    if (!formData.description) {
      errors.description = 'Descrição é obrigatória';
    }
    setErrors(errors);
    return Object.keys(errors).length === 0 && isChecked;
  };

  const handleNewCase = async values => {
    try {
      const caseData = {
        ...values,
        firstName: values.isAnonimous ? 'Anônimo' : values.firstName,
        lastName: values.isAnonimous ? 'Anônimo' : values.lastName,
        email: values.isAnonimous ? 'anonimo@anonimo.com' : values.email,
      };

      const response = await axios.post(
        'https://e7v7h36b03.execute-api.us-east-1.amazonaws.com/prod/cases',
        caseData,
        {
          headers: { 'x-api-key': 'aORCgPG52u7VEtYhfhGtY71inTrZgMe2R9r39ta0' },
        }
      );
      console.log('Resposta do Backend:', response.data);
      setSuccess(true);
      ref.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    } catch (e) {
      if (e.response) {
        console.error('Erro no Backend:', e.response.data);
      } else if (e.request) {
        console.error('Sem resposta do Backend:', e.request);
      } else {
        console.error('Erro durante a solicitação:', e.message);
      }
    }
  };

  const handleButtonClick = () => {
    const isValid = validateForm();
    if (isValid && !success) {
      handleNewCase(formData);
    }
  };

  return (
    <Layout>
      <HeaderIntern />
      <SEO dataSeo={page} />
      <GridContainer>
        <Wrapper>
          <BreadCrumb
            markdown={getMarkdown(page, 'clw9nbp2aj9nb07ln63clcsvv', true)}
            marginTop
          />
        </Wrapper>
        <ContainerContent>
          <Container ref={ref}>
            {success ? (
              <SuccessScreen />
            ) : (
              <Box>
                <Title>Conte sua experiência</Title>
                <SubTitle>Informações de contato</SubTitle>
                <AnonimousDiv>
                  <AnonimousTitle>Não desejo me identificar</AnonimousTitle>
                  <label className="switch">
                    <input
                      type="checkbox"
                      checked={formData.isAnonimous}
                      onChange={() =>
                        setFormData({
                          ...formData,
                          isAnonimous: !formData.isAnonimous,
                        })
                      }
                    />
                    <span className="slider round"></span>
                    
                  </label>
                 
                </AnonimousDiv>
                <DivInput>
                  {formData.isAnonimous && (
                    <>
                     <InformationContent>
                      <InformationTitle>
                          {isWindow && TmpIcon && (
                            <TmpIcon icon={<Info />} size="sm" />)}
                              Você decidiu não se identificar
                      </InformationTitle>
                        <TitleContent>   
                          Sua mensagem será tratada pelo Sírio-Libanês com sigilo e segurança,
                          e mesmo sem se identificar trataremos o seu relato da melhor maneira, 
                          porém, não será possível entrarmos em contato contigo para obtermos maiores detalhes 
                          e lhe dar um retorno sobre a mensagem enviada caso seja necessário.
                          
                        </TitleContent>
                      </InformationContent>
                    </>
                  )}
                </DivInput>

                <DivInput>
                  {!formData.isAnonimous && (
                    <>
                      <br />
                      <NameContainer>
                        <NameContent>
                          {isWindow && TmpInput && (
                            <TmpInput
                              label="Nome:"
                              id="name"
                              name="name"
                              type="text"
                              value={formData.firstName}
                              onChange={handleChange}
                              placeholder="Digite aqui seu nome"
                            />
                          )}
                          {errors.firstName && (
                            <ErrorMessage>{errors.firstName}</ErrorMessage>
                          )}
                        </NameContent>
                        <br />
                        <NameContent>
                          {isWindow && TmpInput && (
                            <TmpInput
                              label="Sobrenome:"
                              id="lastName"
                              name="lastName"
                              type="text"
                              value={formData.lastName}
                              onChange={handleChange}
                              placeholder="Digite aqui seu sobrenome"
                            />
                          )}
                          {errors.lastName && (
                            <ErrorMessage>{errors.lastName}</ErrorMessage>
                          )}
                        </NameContent>
                      </NameContainer>
                      <br />

                      {isWindow && TmpInput && (
                        <TmpInput
                          label="E-mail:"
                          id="email"
                          name="email"
                          type="text"
                          value={formData.email}
                          onChange={handleChange}
                          placeholder="Digite aqui seu e-mail"
                        />
                      )}
                      {errors.email && (
                        <ErrorMessage>{errors.email}</ErrorMessage>
                      )}
                    </>
                  )}
                </DivInput>

                <SubTitle>Sobre a sua solicitação</SubTitle>
                <InputDiv>
                  {isWindow && TmpInput && (
                    <TmpInput
                      label="Assunto:"
                      id="subject"
                      name="subject"
                      type="text"
                      placeholder="Digite o assunto"
                      value={formData.subject}
                      onChange={handleChange}
                    />
                  )}
                  {errors.subject && (
                    <ErrorMessage>{errors.subject}</ErrorMessage>
                  )}
                  <br />

                  {isWindow && TmpTextarea && (
                    <TmpTextarea
                      label="Descrição:"
                      id="description"
                      name="description"
                      placeholder="Digite a descrição"
                      value={formData.description}
                      handleChange={handleChange}
                    />
                  )}
                  {errors.description && (
                    <ErrorMessage>{errors.description}</ErrorMessage>
                  )}
                </InputDiv>
                <br />
                <CheckBoxContainer>
                  {isWindow && TmpCheckbox && (
                    <TmpCheckbox
                      label=""
                      name="checkbox"
                      value="checkbox"
                      checked={isChecked}
                      handleChange={handleCheckboxChange}
                    />
                  )}
                  <CheckBoxTtile>
                    Eu concordo com a{' '}
                    {isWindow && TmpLink && (
                      <TmpLink
                        href="/politica-de-privacidade/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        política do site
                      </TmpLink>
                    )}{' '}
                    para receber informações e contato do Sírio-Libanês
                  </CheckBoxTtile>
                </CheckBoxContainer>
                <Button>
                  {errors.checkbox && (
                    <ErrorMessage>{errors.checkbox}</ErrorMessage>
                  )}

                  {isWindow && TmpButton && (
                    <TmpButton
                      handleClick={handleButtonClick}
                      disabled={!isChecked}
                    >
                      Enviar
                    </TmpButton>
                  )}
                </Button>
              </Box>
            )}
          </Container>
        </ContainerContent>
      </GridContainer>
    </Layout>
  );
};

const FormOuvidoriaPage = () => {
  return (
    <StaticQuery
      query={graphql`
        query {
          gcms {
            site(where: { id: "ckgi8dg9k08b10a74cf20cxgk" }) {
              id
              pages(where: { id: "clw9n6ly6j4jh07mvtscqbat1" }) {
                fragments {
                  id
                  name
                  localizations {
                    singletexts
                    markdown
                  }
                }
                seo {
                  metaTitle
                  metaDescription
                  noIndex
                  localizations {
                    metaTitle
                    metaDescription
                    noIndex
                  }
                }
              }
            }
          }
        }
      `}
      render={response => {
        return <FormOuvidoria page={response.gcms.site.pages} />;
      }}
    />
  );
};

export default FormOuvidoriaPage;
