import React from 'react';
import styled from 'styled-components';
import Success from 'images/success_image.png';

export const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  background-color: #c5d7fb;
  @media (max-width: 648px) {
    background-color: #ffff;
  }
`;

export const Box = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #ffff;
  width: 73rem;
  align-items: center;
  border-radius: 10px;
  margin: 80px 300px;
  padding: 80px;

  @media (max-width: 648px) {
    width: 100%;
    flex-direction: column;
    padding: 10px;
    margin: 20px;
  }
`;

export const SuccessTitle = styled.div`
  font-family: 'Inter', sans-serif;
  font-size: 22px;
  font-weight: 700;
  line-height: 26.4px;
  text-align: center;
  margin: 20px;
`;

export const SuccessSubtitle = styled.div`
  display: flex;
  justify-content: center;
  text-align: center;
  font-family: 'Inter', sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
`;

export const SuccessImage = styled.img`
  width: 10%;
  @media (max-width: 648px) {
    width: 20%;
  }
`;

const SuccessScreen = () => {
  return (
    <Container>
      <Box>
        <SuccessImage src={Success} alt="Imagem tela de sucesso " />
        <SuccessTitle>Sua solicitação foi enviada!</SuccessTitle>
        <SuccessSubtitle>
          Obrigado por entrar em contato conosco. Sua mensagem será analisada
          com atenção.
          <br /> Entraremos em contato em breve, caso seja necessário.
        </SuccessSubtitle>
      </Box>
    </Container>
  );
};

export default SuccessScreen;
